<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      <img
        src="https://echo.genix.co.th/images/img_logo_NTiBUZZ_NT.png"
        width="50"
        alt=""
      />
      © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://web.facebook.com/keng.ntibuzz/?_rdc=1&_rdr"
        target="_blank"
        >NT iBUZZ</b-link
      >
      <span class="d-none d-sm-inline-block"></span>
    </span>

    <span class="float-md-right d-none d-md-block"></span>
  </p>
</template>

<script>
import { BLink } from "bootstrap-vue";

export default {
  components: {
    BLink,
  },
};
</script>
