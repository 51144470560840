<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ userData.title }}
            </p>
            <span class="user-status"> {{ userData.role }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="require('@/assets/images/avatars/7-small.png')"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Inbox</span>
        </b-dropdown-item> -->

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Chat</span>
        </b-dropdown-item> -->

        <!-- <b-dropdown-divider /> -->

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>ออกจากระบบ</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },

  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      // avatarText,
    };
  },


  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  mounted(){
    this.userData = JSON.parse(localStorage.getItem("userData"))
    // console.log(this.userData);
  },


  methods: {
    logout() {
      this.$swal({
        icon: "question",
        title: "ออกจากระบบ",
        text: "ต้องออกจากระบบหรือไม่",
        showCancelButton: true,
        confirmButtonText: "ออกจากระบบ",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        cancelButtonText: "ยกเลิก",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          // Remove userData from localStorage
          // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

          // Remove userData from localStorage
          localStorage.removeItem("userData");
          localStorage.removeItem("logo");
          localStorage.removeItem("director");
          localStorage.removeItem("school_id");
          localStorage.removeItem("school_name");
          localStorage.removeItem("phone");

          // Reset ability
          this.$ability.update(initialAbility);

          // Redirect to login page
          this.$router.push({ name: "auth-login" });
        }
      });
    },
  },

}
</script>
